/* Root  */
body {
  background: linear-gradient(rgba(0, 48, 73, 0.4) 0%, rgba(10, 48, 73, 1) 100%) 0% 0% / 100% 1100px no-repeat;
}

a {
 text-decoration: none;   
}

.modal-backdrop.show {
  opacity: 0.7;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {

  background: rgba(247, 127, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #FF9900;
  border-radius: 4px;
  width: 6px;
  margin:  0 3px 0 3px;
}
/* Main */


.left-col {
  background-image: url(./images/dots.png);
  background-size: cover;
}

.right-col {
  background-size: cover;
}

.daily-missions-container {
  height: 50%;
}

.create-text {
  color: white;
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 10px;
}

.subtext .wallet-adapter-button {
  margin-top: 10px;
  background-color: #ff6b00 !important;
  border:  0px !important;
  padding: 10px 20px;
  color: white;
}

.vault-content .wallet-adapter-button {
  margin-top: 10px;
  background-color: #ff6b00 !important;
  border:  0px !important;
  padding: 10px 20px;
  color: white;
}


.create-btn {
  margin-top: 10px;
  background-color: #ff6b00;
  border:  0px !important;
  padding: 10px 20px;
  color: white;
  margin-left: 20px;
  border-radius: 20px;
}


.daily-missions-container::-webkit-scrollbar {
  width: 0.4em;
  background: rgba(255, 255, 255, 0.08);
}
 
.daily-missions-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.daily-missions-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 1);
  outline: 1px solid slategrey;
}

.bracket-containers span {
}

.top-officers-header {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  padding: 15px 20px;
}

.top-officers-header span {
  text-transform: uppercase;
  color: #ff6b00;
  font-size: 18px;
}

.mission:last-child {
  border: none;
}


/* Sidebar */


/* Buttons */

.btn-orange {
  background: #ff6b00 !important;
}

.btn-blue {
  background: #256FFF !important;
}

/* Text */
.text-blue {
  color: #256FFF;
}

.text-orange {
  color: #FF6B00;
}

.text-green {
  color: rgba(45, 158, 6, 1);
}

/* Pages */
.page-title {
  color: white;
  text-align: center;
  font-family: Chakra Petch;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
}

.page {
  overflow-y: scroll;
  padding-bottom: 8rem !important;
  margin:  0;
}


/* fonts */

.font-m {
  font-size: 14px;
}

.font-n {
  font-size: 16px;
}

.font-x {
  font-size: 18px;
}

.font-xl {
  font-size: 20px;
}


.font-xxl {
  font-size: 24px;
}

/* Width  */
.w-48 {
  width: 48% !important;
}

.w-90 {
  width: 90% !important;
}

/* Navbar  */
.navbar-toggler-icon {
  color: white;
  font-size: 2rem;
  position: absolute;
  top:  0;
  left: 0;
  margin: 30px 10px;
}

.carousel .thumbs-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.carousel .thumb {
  border: 2px solid #c4c4c4 !important;
  border-radius: 4px;
}

.carousel .thumb.selected {
  border: 2px solid #48CAE4 !important;
}

ul {
  padding-left: 0 !important;
}

/* Media queries */

@media (min-width: 576px) {
  main {
    padding-left: 6rem;
  }
}

@media (min-width: 1000px) {
  .modal-dialog {
    max-width: 800px !important;
  }
}

@media (max-width: 576px) {
  .navbar {
    padding: 0;
  }

  .sidebar .fa-bars {
    margin: 1.5rem 1rem;
    font-size: 24px;
    color: white;
  }

  .top-row {
    overflow-x: hidden;
  }

  .page {
    overflow-x: hidden !important;
  }

  .notification {
    padding: 0.75rem 0;
  }

  .stats-container {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .stats-container .title-text {
    font-size: 16px;
    text-align: center !important;
    width: 100%;
  }

  .stats-container .title-value {
    font-size: 24px;
    width: 100%;
    text-align: center !important;
  }

  .navbar {
    padding: 0 !important;
  }

  .sidebar {
    padding: 0;
    position: absolute;
    z-index: 1032;
  }

  .sidebar-modal {
    width: 100% !important;
    z-index: 1031;
    padding: 0;
    color: white;
    text-align: center;
    font-family: Chakra Petch;
    text-transform: uppercase;
  }
    
  .sidebar-modal .modal-dialog {
    position: absolute;
    margin:  0;
    border-radius: 0;
    height: 100%;
    background: transparent;
    width: 100%;
  }

  .sidebar-modal .modal-header {
      font-size: 18px;
      font-weight: bold;
  }

  .sidebar-modal .modal-content {
    position: absolute;
    margin:  0;
    border-radius: 0;
    height: 100%;
    background: #00061A;
    width: 65%;
    color: white;
  }

  .sidebar-modal .modal-body{
    padding: 0;
  }

  .sidebar-modal span {
    color: white;
    text-decoration: none;
    font-size: 14px;
  }

  .sidebar-modal a {
    color: white;
    text-decoration: none;
    font-size: 14px;
  }

  .sidebar-modal .item-text{
    margin-left: 10px;
  }

  .navbar {
    height: 5rem;
  }

  .navbar-brand {
    margin-left: 40px;
  }
}

@media (max-width: 480px) {
  .sidebar-modal .modal-content {
    width: 50% !important;
  }
}
